import React from 'react';
import { RoutesPath } from './Router/RoutesPath';
import { ComplexNavbar } from './utility/NavBar';
import { useAuth } from './auth/AuthControl';
import { Login } from './components/auth/login/Login';
function App() {
  const { isAuthenticated } = useAuth();
  


  if(!isAuthenticated){
    return <Login/>
  }


  // Re-check user double login



  
  




  return (
    <div className="App  flex ">
      <RoutesPath />
      <ComplexNavbar/>
    </div>
  );
}

export default App;
