import axios from "axios";
import { motion } from "framer-motion";
import { useEffect } from "react";
import { Link } from "react-router-dom";

interface MenuItems {
  name: string;
  src: string;
  typeOfAsset : string,
  notify: number;
}

const ListMenu: MenuItems[] = [
  {
    name: "บ้านเดี่ยว",
    src: "https://cdn-icons-png.flaticon.com/512/1040/1040993.png",
    typeOfAsset : "house",
    notify: 10,
  },

  {
    name: "ทาวน์เฮ้าส์",
    src: "https://cdn-icons-png.flaticon.com/512/2775/2775074.png",
    typeOfAsset : "townhouse",
    notify: 10,
  },
  {
    name: "คอนโด",
    src: "https://cdn-icons-png.flaticon.com/512/6849/6849725.png",
    typeOfAsset : "condo",
    notify: 10,
  },
  {
    name: "อาคารพาณิชย์",
    src: "https://cdn-icons-png.flaticon.com/512/1684/1684121.png",
    typeOfAsset : "commercial",
    notify: 10,
  },
  {
    name: "ที่ดิน",
    src: "https://cdn-icons-png.flaticon.com/512/6284/6284836.png",
    typeOfAsset : "land",
    notify: 10,
  },


 

];




function handleSelectedLeadAsset(typeOfAsset : string){
    sessionStorage.setItem("typeOfAsset" , typeOfAsset)
    window.location.replace("/create/post")
}

export default function SelectPostType(): JSX.Element {
  useEffect(()=>{
    try {
      const session_lead = sessionStorage.getItem("lead_id_posts")
      if(session_lead){
        axios.post(`${process.env.REACT_APP_ADMIN_API}/reports/lead/detail`,{
          lead_id : session_lead
        }).then((res)=>{console.log(res.data)
        
          const leadUname = res.data.leadUserUname;

          if(leadUname){
            sessionStorage.setItem("leadUname_post" , leadUname)
          }
        }).catch((err)=>{
          // Swal.fire(`${}`)
          console.log(err)
        })
      }
      
    } catch (error) {
        console.error(error)
    }
  },[])
  return (
    <main className="w-full h-full ">
      <div className="h-full flex ">
        <motion.div
          className="flex flex-col justify-center gap-x-2.5  w-full h-max  p-8   md:flex-row md:mt-36 flex-wrap"
        //   drag="y" // Allow horizontal dragging
        >
          {ListMenu.map((data, index) => {
            return (
                  
              <motion.div
                key={index}
                className="relative flex flex-col justify-center  items-center h-56 mt-8 w-full cursor-pointer hover:bg-gray-100 rounded-2xl  md:mt-0 md:w-72 border"
                whileHover={{ scale: 1.05 }} // Scale on hover
                onClick={()=>handleSelectedLeadAsset(data.typeOfAsset)}
              >
                <img src={data.src} alt={data.name} className="w-24 md:w-40" />
                <h2 className="text-xl mt-2.5 font-main">{data.name}</h2>
              </motion.div>

            );
          })}
        </motion.div>
      </div>
    </main>
  );
}
