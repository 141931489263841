import { motion } from "framer-motion";
import { Link } from "react-router-dom";
interface MenuItems {
  name: string;
  src: string;
  link : string,
  notify: number;
}

const ListMenu: MenuItems[] = [


  {
    name: "configuration",
    src: "   https://cdn-icons-png.flaticon.com/512/6024/6024190.png ",
    link : "/config/employees",
    notify: 10,
  },
  {
    name: "แนะนำทรัพย์ (Lead)",
    src: "https://cdn-icons-png.flaticon.com/512/9119/9119230.png",
    link : "/lead",
    notify: 10,
  },
  {
    name: "ช่วยขาย (Help Post)",
    src: "https://cdn-icons-png.flaticon.com/512/9202/9202573.png",
    link : "/postperty/help",
    notify: 10,
  },
  {
    name: "โพสต์ทั้งหมด (Post)",
    src: "https://cdn-icons-png.flaticon.com/512/9746/9746243.png",
    link : "/assets",
    notify: 10,
  },
  {
    name: "สมาชิกทั้งหมด (Members)",
    src: "https://cdn-icons-png.flaticon.com/512/5482/5482062.png",
    link : "/members",
    notify: 10,
  },
  {
    name: "แจ้งปัญหา (Report)",
    src: "https://cdn-icons-png.flaticon.com/512/6013/6013913.png",
    link : "/reports",
    notify: 10,
  },

  {
    name: "Commission",
    src: "https://cdn-icons-png.flaticon.com/512/2108/2108625.png",
    link : "/commission",
    notify: 10,
  },
  {
    name: "VerifyUser",
    src: "https://cdn-icons-png.flaticon.com/512/3187/3187927.png",
    link : "/verify/user",
    notify: 10,
  },
  {
    name: "การเงิน",
    src: "https://cdn-icons-png.flaticon.com/512/2821/2821739.png",
    link : "/withdrawlogs",
    notify: 10,
  },

  {
    name: "จัดการ Onsite",
    src: "https://cdn-icons-png.flaticon.com/512/717/717498.png",
    link : "/onsite_workflow",
    notify: 10,
  },

  {
    name: "เอกสาร onsite",
    src: "https://cdn-icons-png.flaticon.com/512/2014/2014997.png",
    link : "/onsite_allDocument",
    notify: 10,
  },

  // {
  //   name: "LEAD FROM ONSITE TEAM",
  //   src: "https://cdn-icons-png.flaticon.com/512/2659/2659360.png",
  //   link : "/onsite_lead",
  //   notify: 10,
  // },
  // {
  //   name: "บันทึกประวัติทรัพย์Lead",
  //   src: "https://cdn-icons-png.flaticon.com/512/2822/2822687.png",
  //   link : "/lead/note",
  //   notify: 10,
  // },


 
];

export default function Index(): JSX.Element {
  return (
    <main className="w-full h-full ">
      <div className="h-full flex ">
        <motion.div
          className="flex flex-col justify-center gap-x-2.5  w-full h-max  p-8   md:flex-row md:mt-36 flex-wrap"
        //   drag="y" // Allow horizontal dragging
        >
          {ListMenu.map((data, index) => {
            return (
                <Link to={`${data.link}`} className="mt-2.5">
                  
              <motion.div
                key={index}
                className="relative flex flex-col justify-center  items-center h-56 mt-8 w-full cursor-pointer hover:bg-gray-100 rounded-2xl  md:mt-0 md:w-72 border"
                whileHover={{ scale: 1.05 }} // Scale on hover
              >
                <img src={data.src} alt={data.name} className="w-24 md:w-40" />
                <h2 className="text-xl mt-2.5 font-main">{data.name}</h2>
              </motion.div>
              </Link>

            );
          })}
        </motion.div>
      </div>
    </main>
  );
}
