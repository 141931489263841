import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Index from "../components/index/Index";
import { SidebarWithLogo } from "../utility/SideBar";
import { SidebarProvider } from '../context/SidebarContext';
import {PostAssets} from "../components/posts/PostAssets";
import { Reports } from "../components/Report/IReport";
import { Login } from "../components/auth/login/Login";
import { Test } from "../components/Report/Test";
import { TableMembers } from "../components/Members/TableMembers";
import { CreatePost } from "../components/posts/CreatePost";
import ConfigEmoployees from "../config/employees/ConfigEmployees";
import Lead from "../components/Lead_System/Lead";
import LeadOfNote from "../components/Lead_System/Note"
import EditMember from "../components/Members/EditMember";
import Dashboard from "../components/Dashboard/Dashboard";
import SelectPostType from "../components/posts/SelectPostType";
import MainPageCommission from "../components/Commission/MainPage";
import HelpPost from "../components/Commission/HelpPost";
import ApprovedAsset from "../components/Commission/ApprovedAsset";
import { EditPost } from "../components/posts/EditPost";
import WithDrawLogs from "../components/Commission/WithDrawLogs";
import ApprovedMoneyForm from "../components/Commission/ApprovedMoneyForm";
import VerifyUser from "../components/Commission/VerifyUser";
import OnsiteWorkFlow from "../components/onsite/OnsiteWorkFlow";
import OnsiteAllDocument from "../components/onsite/OnsiteAllDocument";
import LeadFromTeamOnsite from "../components/onsite/OnsiteLead";
export function RoutesPath() {
  return (
    <Router>
      <SidebarProvider>
          <SidebarWithLogo />
          <Routes>
            <Route path="/" element={<Index />} />
            <Route path="/lead" element={<Lead />} />
            <Route path="/lead/note" element={<LeadOfNote />} />
            <Route path="/dashboard" element={<Dashboard/>}/>
            <Route path="/create/select/post" element={<SelectPostType/>}/>
            <Route path="/edit/post" element={<EditPost/>}/>
            <Route path="/create/post" element={<CreatePost />} />
            <Route path="/postperty/help" element={<HelpPost />} />
            <Route path="/test" element={<Test />} />
            <Route path="/auth/login" element={<Login />} />
            <Route path="/assets" element={<PostAssets />} />
            <Route path="/reports" element={<Reports />} />
            <Route path="/members" element={<TableMembers/>}/>
            <Route path="/edit/member/:uid" element={<EditMember/>}/>
            <Route path="/config/employees" element={<ConfigEmoployees/>}/>
            <Route path="/commission" element={<MainPageCommission/>}/>
            <Route path="/approved/asset" element={<ApprovedAsset/>}/>
            <Route path="/withdrawlogs" element={<WithDrawLogs/>} />
            <Route path="/withdrawlogs/approved" element={<ApprovedMoneyForm/>} />
            <Route path="/verify/user" element={<VerifyUser/>} />
            <Route path="/onsite_workflow" element={<OnsiteWorkFlow/>} />
            <Route path="/onsite_allDocument" element={<OnsiteAllDocument/>} />
            <Route path="/onsite_lead" element={<LeadFromTeamOnsite/>} />



          </Routes>
      </SidebarProvider>
    </Router>
  )
}
