import { useState, useCallback } from 'react';

export const useAuth = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(() => {
        const token = localStorage.getItem("token");
        const permission = localStorage.getItem("permission");

        return Boolean(token && permission);
    });

    const login = useCallback(() => {
        // Implement your login logic here
        // For example, set isAuthenticated to true after successful authentication
        setIsAuthenticated(true);
    }, []);

    const logout = useCallback(() => {
        // Implement your logout logic here
        // For example, set isAuthenticated to false and remove token/permission from localStorage
        setIsAuthenticated(false);
        localStorage.removeItem("token");
        localStorage.removeItem("permission");
        window.location.replace('/auth/login')
    }, []);

    return { isAuthenticated, login, logout };
};
